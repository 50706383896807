<style lang="scss">
.index {
  width: 100vw;
  height: 100vh;
  canvas {
    top: 0;
  }
}
</style>

<template>
  <div class="index">
    <!-- 头部 -->
    <custom-header :isLoad="isLoad"></custom-header>

    <!-- 路由 -->
    <router-view v-if="isLoad"></router-view>

    <!-- 尾部 -->
    <!-- <custom-footer></custom-footer> -->

    <!-- 场景 -->
    <scene @load="load"></scene>

    <!-- 加载 -->
    <loading v-if="!isLoad"></loading>
    <!-- <loading v-if="true"></loading> -->
  </div>
</template>

<script setup>
import customHeader from "./components/custom-header/index.vue";
import customFooter from "./components/custom-footer/index.vue";
import scene from "./../../components/three-scene/index.vue";
import { ref } from "vue";

const isLoad = ref(false);
const load = () => {
  isLoad.value = true;
};
</script>
